<template>
  <view-item title="配件出库">
    <template #operation>
      <en-button type="primary" @click="operation.add.click">创建单据</en-button>
      <button-ajax v-if="form.data.status?.code === 'A'" type="primary" :method="operation.cancel.click"> 退单 </button-ajax>
      <button-ajax v-if="form.data.id" :disabled="form.disabled" type="primary" :method="operation.save.click">保存</button-ajax>
      <en-dropdown v-if="form.data.id" @command="operation.option.command">
        <en-button>操作</en-button>
        <template #dropdown>
          <en-dropdown-item :disabled="form.disabled" command="detail"> 编辑移库出库单 </en-dropdown-item>
          <en-dropdown-item command="audit">审核</en-dropdown-item>
          <en-dropdown-item command="logs">单据历史</en-dropdown-item>
          <en-dropdown-item :disabled="form.disabled" command="export">导出</en-dropdown-item>
          <en-dropdown-item :disabled="form.disabled" command="delete">删除</en-dropdown-item>
        </template>
      </en-dropdown>
    </template>

    <template #manifest>
      <table-manifest
        code="STFOSEFD"
        :ajax="{
          action: 'GET /enocloud/stock/transfer/out/query',
          params: (params) => (params.payload = { rsexp: 'id,serialNo,status' })
        }"
        :props="{ start: 'startDate', end: 'endDate' }"
        :routes="[
          { path: '/accessory/stock/out/sale', name: '销售单' },
          { path: '/accessory/stock/out/refund', name: '销退单' },
          { path: '/accessory/stock/out/other', name: '其他出货单' },
          { path: '/accessory/stock/out/transfer', name: '移库出库单' }
        ]"
        @row-click="manifest.row.click"
        @expand-click="dialog.visible = true"
        :ref="setRef('manifest')"
      >
        <template #STATUS="{ row }: { row: EnocloudInventoryDefinitions['StockTransferOutDto'] }">
          <en-tag :type="row.status?.code === 'S' ? 'success' : row.status?.code === 'P' ? 'warning' : 'danger'">{{ row.status?.message }}</en-tag>
        </template>

        <template #form="{ data }">
          <en-form-item label="单号">
            <en-input v-model="data.serialNo"></en-input>
          </en-form-item>
          <en-form-item label="移出仓库">
            <select-maintain
              v-model="data.fromWarehouseId"
              :ajax="{
                action: 'GET /enocloud/common/warehouse',
                params: (params, value) => (params.payload = { name: value })
              }"
              :props="{ label: 'name', value: 'id' }"
              value-key="id"
              remote
   
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="移入仓库">
            <select-maintain
              v-model="data.toWarehouseId"
              :ajax="{
                action: 'GET /enocloud/common/warehouse',
                params: (params, value) => (params.payload = { name: value })
              }"
              :props="{ label: 'name', value: 'id' }"
              value-key="id"
              remote
         
              class="w-full"
            ></select-maintain>
          </en-form-item>

          <en-form-item label="制单人">
            <select-maintain
              v-model="data.preparedById"
              :ajax="{
                action: 'GET /enocloud/common/user',
                params: (params, value) => (params.payload = { name: value })
              }"
              :props="{ label: 'name', value: 'id' }"
              value-key="id"
              remote
              
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="经手人">
            <select-maintain
              v-model="data.auditById"
              :ajax="{
                action: 'GET /enocloud/common/user',
                params: (params, value) => (params.payload = { name: value })
              }"
              :props="{ label: 'name', value: '' }"
              value-key="id"
              remote
              
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="备注">
            <en-input v-model="data.comment"></en-input>
          </en-form-item>
        </template>
      </table-manifest>
    </template>

    <view-item-content :disabled="!form.data.id">
      <en-card v-loading="form.loading" body-class="flex flex-col gap-6">
        <div class="flex gap-4">
          <span>业务选择:移库出库</span>
        </div>

        <en-collapse>
          <en-collapse-item>
            <template #title>
              <div class="flex items-center gap-6">
                移出仓库<span>{{ form.data.fromWarehouse?.name }}</span> 移入仓库<span>{{ form.data.toWarehouse?.name }}</span> 制单人
                <span>{{ form.data.preparedBy?.name }}</span> 经手人 <span>{{ form.data.preparedBy?.name }}</span>
                <en-tag :type="form.data.status?.code === 'S' ? 'success' : form.data.status?.code === 'P' ? 'warning' : 'danger'">{{
                  form.data.status?.message
                }}</en-tag>
                <span>{{ form.data.serialNo }}</span>
                <span>{{ formatDate(form.data.auditDatetime) }}</span>
              </div>
            </template>

            <en-form label-position="right" class="grid grid-cols-3">
              <en-form-item label="备注">{{ form.data.comment }}</en-form-item>
            </en-form>
          </en-collapse-item>
        </en-collapse>

        <div class="flex items-center gap-6 text-xs text-neutral-500">
          <div class="flex flex-col items-center gap-2">
            <span>总数量</span>
            <span>{{ formatNumber(form.summary?.count) }}</span>
          </div>
          <div class="flex flex-col items-center gap-2">
            <span>总金额</span>
            <span>{{ formatMoney(form.summary?.primeCost) }}</span>
          </div>
        </div>
      </en-card>

      <en-card v-loading="form.loading" class="flex-1 overflow-auto" body-class="h-full">
        <flex-box>
          <template #default="{ height }">
            <en-tabs v-model="tabs.active">
              <en-tab-pane
                label="配件"
                name="goods"
                :badge="form.data.stockTransferOutGoods?.length"
                :style="{ height: height - 55 + 'px', overflow: 'auto' }"
                class="gap-6"
              >
                <div class="flex gap-4">
                  <en-button type="primary" :disabled="form.disabled" @click="form.stockTransferOutGoods.batch.click">批量添加配件</en-button>
                </div>

                <flex-box>
                  <template #default="{ height }">
                    <table-dynamic code="STFODLFD" :data="form.data.stockTransferOutGoods" :height="height">
                      <template #OPERATION="{ row, $index }: { row: EnocloudInventoryDefinitions['StockTransferOutGoodsDto']; $index: number }">
                        <en-button type="primary" link :disabled="form.disabled" @click="form.stockTransferOutGoods.delete.click($index)">
                          删除
                        </en-button>
                      </template>

                      <template #NO="{ $index }: { $index: number }">{{ $index + 1 }}</template>

                      <template #OEM="{ row }: { row: EnocloudInventoryDefinitions['StockTransferOutGoodsDto'] }">{{ row.goods?.oem }}</template>

                      <template #NAME="{ row }: { row: EnocloudInventoryDefinitions['StockTransferOutGoodsDto'] }">{{ row.goods?.name }}</template>

                      <template #UNIT="{ row }: { row: EnocloudInventoryDefinitions['StockTransferOutGoodsDto'] }">
                        <select-maintain
                          v-model="row.goodsSpecification"
                          :options="row.goods?.specifications"
                          :props="{ label: 'name', value: '' }"
                          value-key="id"
                          :disabled="form.disabled"
                        ></select-maintain>
                      </template>

                      <template #SPECIFICATION="{ row }: { row: EnocloudInventoryDefinitions['StockTransferOutGoodsDto'] }">
                        {{ `${row.goodsSpecification?.weight}${row.goods?.warehouseUnit}` }}
                      </template>

                      <template #BRAND="{ row }: { row: EnocloudInventoryDefinitions['StockTransferOutGoodsDto'] }">
                        {{ row.goods?.brand }}
                      </template>

                      <template #PRODUCTION_PLACE="{ row }: { row: EnocloudInventoryDefinitions['StockTransferOutGoodsDto'] }">
                        {{ row.goods?.placeOfProduction }}
                      </template>

                      <template #BARCODE="{ row }: { row: EnocloudInventoryDefinitions['StockTransferOutGoodsDto'] }">
                        {{ row.goods?.barcode }}
                      </template>

                      <template #COUNT="{ row }: { row: EnocloudInventoryDefinitions['StockTransferOutGoodsDto'] }">
                        <en-input-number v-model="row.count" :min="0" :disabled="form.disabled" class="w-full"></en-input-number>
                      </template>

                      <template #MODEL="{ row }: { row: EnocloudInventoryDefinitions['StockTransferOutGoodsDto'] }">
                        {{ row.goods?.model }}
                      </template>

                      <template #COMMENT="{ row }: { row: EnocloudInventoryDefinitions['StockTransferOutGoodsDto'] }">
                        <en-input v-model="row.comment" :disabled="form.disabled"></en-input>
                      </template>
                    </table-dynamic>
                  </template>
                </flex-box>
              </en-tab-pane>
            </en-tabs>
          </template>
        </flex-box>
      </en-card>
    </view-item-content>
  </view-item>

  <stock-out-transfer-dialog v-model="dialog.visible"></stock-out-transfer-dialog>

  <stock-out-transfer-detail v-model="detail.visible" :data="form.data" @confirm="detail.confirm"></stock-out-transfer-detail>

  <stock-out-transfer-logs v-model="logs.visible" :data="form.data"></stock-out-transfer-logs>

  <inventory-goods-select
    v-model="selection.visible"
    type="stock-transfer"
    @confirm="selection.confirm"
    :params="{ warehouseId: form.data.fromWarehouse?.id }"
  ></inventory-goods-select>
</template>

<script lang="ts">
import { h } from 'vue'
import { find } from 'lodash-es'
import { EnMessageBox } from '@enocloud/components'
import { InventoryGoodsSelect, type GoodsSelectionOption } from '@enocloud/business'
import { calculator } from '@enocloud/utils'

import StockOutTransferDialog from '@accessory/components/stock-out-transfer-dialog.vue'
import StockOutTransferDetail from '@accessory/components/stock-out-transfer-detail.vue'
import StockOutTransferLogs from '@accessory/components/stock-out-transfer-logs.vue'

export default factory({
  components: {
    StockOutTransferDialog,
    StockOutTransferDetail,
    InventoryGoodsSelect,
    StockOutTransferLogs
  },

  config: {
    children: {
      operation: {
        children: {
          add: {
            click() {
              this.form.init()
              this.detail.visible = true
            },
            command(option: string) {
              switch (option) {
                case 'sale':
                  this.detail.code = 'SAL'
                  this.detail.visible = true
                  break
                case 'refund':
                  this.detail.code = 'SALR'
                  this.detail.visible = true
                  break
                case 'other':
                  this.detail.code = 'OTH'
                  this.detail.visible = true
                  break
                case 'transfer':
                  this.detail.code = 'TRA'
                  this.detail.visible = true
                  break
              }
            }
          },
          cancel: {
            async click() {
          
     
               this.form.cancel()
              this.refs.manifest.table.method()
            }
          },
          save: {
            async click() {
              await this.form.update()
              return this.form.get()
            }
          },
          commit: {
            async click() {
              await this.form.update()
              await this.form.audit({ addition: { id: this.form.data.id, additionalOperationCode: 'ADSI' } })
              return this.form.get()
            }
          },
          print: {
            click() {
              print(Purchase, { props: { data: this.form.data } })
            }
          },
          option: {
            command(option: string) {
              switch (option) {
                case 'detail':
                  this.detail.visible = true
                  break
                case 'logs':
                  this.logs.visible = true
                  break
                case 'audit':
                 
                case 'delete':
                  this.form.delete()
                  this.form.init()
              }
            }
          }
        }
      },
      manifest: {
        row: {
          click(row: EnocloudInventoryDefinitions['StockOutDto']) {
            this.form.init()
            this.form.data.id = row.id
            this.form.get()
          }
        }
      },
      tabs: {
        active: 'goods'
      },
      form: {
        ajax: {
          get: {
            action: 'GET /enocloud/stock/transfer/out/:stockTransferOutId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'POST /enocloud/stock/transfer/out',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          update: {
            action: 'PUT /enocloud/stock/transfer/out',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          cancel: {
            action: 'POST /enocloud/stock/transfer/out/:stockTransferOutId/audit/reverse',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          delete: {
            action: 'DELETE /enocloud/stock/transfer/out/:stockTransferOutId',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          audit: {
            action: 'POST /enocloud/stock/transfer/out/:stockTransferOutId/audit',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          }
        },
        computed: {
          disabled() {
            return this.form.data.status?.code === 'F' || this.form.data.status?.code === 'C'
          },
          summary() {
            return this.form.data.stockTransferOutGoods?.reduce(
              (res, item) => {
                res.count = calculator.add(res.count, item.count)

                return res
              },
              {
                count: 0
              }
            )
          }
        },
        children: {
          imgUrls: {
            click() {
              EnMessageBox({
                title: '物流单照片',
                message: h(
                  'div',
                  { class: 'flex gap-4 flex-wrap' },
                  this.form.data.imgUrls.map((url) => {
                    return h('img', { src: url, width: 300, height: 300 })
                  })
                )
              })
            }
          },
          stockTransferOutGoods: {
            add: {
              change(value: EnocloudCommonDefinitions['GoodsDto']) {
                this.form.data.stockTransferOutGoods?.push({
                  goods: value,
                  goodsSpecification: value.specifications?.find((item) => item.defaultPurchase?.value) || value.specifications?.[0]
                })
              }
            },
            batch: {
              click() {
                this.selection.visible = true
              }
            },
            delete: {
              click(index: number) {
                this.form.data.stockTransferOutGoods?.splice(index, 1)
              }
            }
          }
        }
      },
      dialog: {
        visible: false
      },
      detail: {
        visible: false,
        confirm(id: number | undefined) {
          this.form.data.id ??= id
          this.form.get()
        }
      },
      logs: {
        visible: false
      },
      selection: {
        visible: false,
        confirm(items: GoodsSelectionOption[]) {
          for (const item of items) {
            const exist = find(this.form.data.stockTransferOutGoods, ['goods.id', item.goods.id])
            if (!exist) {
              this.form.data.stockTransferOutGoods.push({
                goods: item.goods,
                goodsSpecification: item.goodsSpecification,
                batch: item.batch,
                count: item.count
              })
            } else {
              exist.count ??= 0
              exist.count += item.count
            }
          }
        }
      }
    }
  }
})
</script>
